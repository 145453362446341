import React from "react";
import styled from "styled-components";
import { FieldRenderProps } from "react-final-form";
import DefaultSelectField from "src/Modules/Core/Components/Form/Select/DefaultSelectField/DefaultSelectField";
import CreatableSelect from "react-select/creatable";
import { DefaultSelectStyle } from "src/Modules/Core/Components/Form/Select/DefaultSelectStyle";

const Container = styled.div``;

type SelectFieldProps = FieldRenderProps<[], any>;

const InterestsField: React.FC<SelectFieldProps> = (props) => {
  let selectedOptions = props.input?.value?.map((i) => {
    return { value: i, label: i };
  });

  return (
    <CreatableSelect
      onChange={(newValue: any, actionMeta: any) => {
        if (props.isMulti) {
          props.input.onChange(newValue.map((i: any) => i.value));
        } else {
          props.input.onChange(newValue.value);
        }
      }}
      value={selectedOptions}
      {...props}
      styles={DefaultSelectStyle}
    />
  );
};

export default InterestsField;
