import React from "react";
import styled from "styled-components";
import { FieldRenderProps } from "react-final-form";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 48px;
`;

const Input = styled.input`
  padding: 12px;

  flex: 1;
  height: 48px;

  background: #ffffff;
  /* Notch/Neutral/100 */

  border: 1px solid #ededf0;
  border-radius: 0 6px 6px 0;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;

  &:focus {
    box-shadow: 0px 0px 0px 2px #e6eefe;
    border: 1px solid #105cf7;
    outline: none;
  }

  &:disabled {
    background: #fafafb;
    color: #9fa4b3;
  }
`;

const Prefix = styled.div`
  background: #fafafb;
  width: fit-content;
  padding: 0 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.03em;

  /* Notch/Neutral/400 */

  color: #9fa4b3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  border-radius: 6px 0 0 6px;
  border: 1px solid #ededf0;
  border-right: none;
`;

type Props = FieldRenderProps<string, any>;

const TextInputField: React.FC<Props> = (props) => {
  return (
    <Container>
      <Prefix>{props.prefix}</Prefix>
      <Input {...props.input} {...props} />
    </Container>
  );
};

export default TextInputField;
