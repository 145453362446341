import { StylesConfig } from "react-select";

type OptionType = {
  value: string;
  label: string;
};

export const DefaultSelectStyle: StylesConfig<OptionType, true> = {
  control: (provided, state) => ({
    ...provided,
    margin: 0,
    padding: 0,
    boxShadow: state.isFocused ? "0px 0px 0px 2px #e6eefe" : "none",
    height: "fit-content",
  }),
  container: (provided, state) => ({
    height: "fit-content",
    position: "relative",
    padding: "0",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    position: "static",
    padding: "2px 12px",
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
  }),
  dropdownIndicator: (provided, state) => ({
    width: 20,
    height: 20,
    marginRight: "12px",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    display: "none",
  }),
  // Input
  placeholder: (provided, state) => ({
    ...provided,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.7,
    letterSpacing: "-0.03em",
    color: "#9FA4B3",
    display: state.isFocused ? "none" : "initial",
  }),
  input: (provided, state) => ({
    ...provided,
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.7,
    letterSpacing: "-0.03em",
    color: "#1c274a",
  }),
  // Menu
  menu: (provided, state) => ({
    ...provided,
    background: "#FFFFFF",
    boxShadow: "0px 2px 16px rgba(28, 39, 74, 0.06)",
    borderRadius: "6px",
    zIndex: 1000,
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.7,
    letterSpacing: "-0.03em",
    color: "#1c274a",
    height: 48,
    padding: "0 12px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "white",
    cursor: "pointer",
    "&:hover": {
      background: "#F8FAFF",
    },
  }),
  group: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  // Values
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.7,
    letterSpacing: "-0.03em",
    color: "#1c274a",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    background: "#F8FAFF",
    borderRadius: 6,
    height: 28,
    padding: "2px 0 2px 8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1,
    letterSpacing: "-0.03em",
    color: "#09348B",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: "#1D7C93",
    backgroundColor: "none",
    width: 20,
    height: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 1,
    "& svg": {
      width: 20,
      height: 20,
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: "pink",
      cursor: "pointer",
    },
  }),
};
