import React, { useRef } from "react";
import styled from "styled-components";
import { FieldRenderProps } from "react-final-form";

const Icon = styled.div`
  width: 24px;
  height: 24px;
  background: #f8faff;
  border-radius: 999px;
  margin-right: 8px;

  color: #105cf7;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  flex: 0 0 auto;
`;

const Input = styled.input`
  background: white;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
  border: 1px solid transparent;
  outline: none;
  flex: 1 1 auto;
  width: 120px;
`;

const Label = styled.div`
  margin-left: auto;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: -0.03em;
  flex: 0 0 auto;
  color: #9fa4b3;
  width: 130px;
  display: flex;
`;

const BigContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  padding: 12px;

  width: 100%;
  max-width: 300px;
  height: 48px;

  background: #ffffff;
  /* Notch/Neutral/100 */

  border: 1px solid #ededf0;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;

  &.active {
    box-shadow: 0px 0px 0px 2px #e6eefe;
    border: 1px solid #105cf7;
    outline: none;
  }
`;

const MonthlyRate = styled.div`
  display: inline-flex;
  flex-direction: row;
  padding: 4px 12px;
  height: 32px;
  width: 140px;
  flex: 1;
  margin-top: 4px;

  background: #fafafb;

  border: 1px solid #ededf0;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #9fa4b3;

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    letter-spacing: -0.03em;

    color: #1c274a;
    margin-left: 4px;
  }
`;

type Props = FieldRenderProps<number, any>;

const numberOnChange = (value: string, pastValue: number): number => {
  let asNumber = Number(value);

  if (Number.isNaN(asNumber)) {
    return pastValue;
  }

  return asNumber > 999999 ? 999999 : asNumber;
};

const monthlyCalc = (value: any) => {
  let asNumber = Number(value);

  if (Number.isNaN(asNumber)) {
    return "";
  }

  asNumber = asNumber / 12;

  return Math.trunc(asNumber).toLocaleString();
};

const YearlySalaryInputField: React.FC<Props> = (props) => {
  let ref = useRef<HTMLInputElement>(null);

  return (
    <BigContainer>
      <Container
        className={ref.current == document.activeElement ? "active" : ""}
      >
        <Icon>$</Icon>
        <Input
          pattern="[0-9]+"
          ref={ref}
          onChange={(value: any) => {
            let result = numberOnChange(value.target.value, props.input.value);
            props.input.onChange(String(result));
          }}
          value={props.input.value}
        />
        <Label>USD per year</Label>
      </Container>
      <MonthlyRate>
        Monthly: <span>${monthlyCalc(props.input.value)}</span>
      </MonthlyRate>
    </BigContainer>
  );
};

export default YearlySalaryInputField;
