import React from "react";
import styled from "styled-components";
import { FieldRenderProps } from "react-final-form";
import DeleteBack2LineIcon from "remixicon-react/DeleteBack2LineIcon";
import CloseLineIcon from "remixicon-react/CloseLineIcon";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  padding: 12px;

  width: 100%;
  height: 48px;

  gap: 8px;

  background: #ffffff;
  /* Notch/Neutral/100 */

  border: 1px solid #ededf0;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;

  &.active {
    box-shadow: 0px 0px 0px 2px #e6eefe;
    border: 1px solid #105cf7;
    outline: none;
  }

  &::placeholder {
    color: #9fa4b3;
  }
`;

const InputInside = styled.input`
  background: white;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
  border: 1px solid transparent;
  outline: none;
  flex: 1 1 auto;
  width: 120px;

  &::placeholder {
    color: #9fa4b3;
  }
`;

const Icon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

type Props = FieldRenderProps<string, any>;

const TextInputField: React.FC<Props> = (props) => {
  let icon = props.icon;

  return (
    <Container>
      <Icon>{props.icon}</Icon>
      <InputInside {...props.input} {...props} />
    </Container>
  );
};

export default TextInputField;
