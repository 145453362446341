import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div<CompanyLogoProps>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 6px;
  contain: paint;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
  }

  img {
    width: 100%;
    height: auto;
  }
`;

type Props = {
  src?: string;
  size: number;
  fallback: Element;
};

const ImageWithFallback: React.FC<Props> = (props) => {
  let [loading, setLoading] = useState(true);
  let [found, setFound] = useState(false);
  let [image, setImage] = useState<HTMLImageElement | null>(null);
  let [debounce, setDebounce] = useState<any>(null);
  let [status, setStatus] = useState<string>("init");

  useEffect(() => {
    if (props.src == undefined) {
      return;
    }

    if (debounce != undefined) {
      window.clearTimeout(debounce);
    }

    if (props.src !== image?.src) {
      setLoading(true);
    }

    let timeoutHandler = window.setTimeout(() => {
      if (props.src == undefined) {
        return;
      }

      let image = new Image();

      image.onload = () => {
        setLoading(false);
        setFound(true);
        setImage(image);
      };

      image.onerror = () => {
        setLoading(false);
        setFound(false);
      };

      image.src = props.src;

      setImage(image);
    }, 800);

    setDebounce(timeoutHandler);

    return () => window.clearTimeout(debounce);
  }, [props.src]);

  if (loading) {
    return <Container {...props} />;
  }

  if (!found) {
    return props.fallback;
  }

  return (
    <Container {...props}>
      <img src={image?.src} />
    </Container>
  );
};

export default ImageWithFallback;
