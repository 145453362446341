import React, { ReactNode } from "react";
import {
  appLayoutStyle,
  appContentStyle,
  contentContainerStyle,
} from "src/Modules/Shared/Components/Layout/layout.css";
import AppResponsiveSidebarNavigation from "src/Modules/Shared/Components/Layout/AppResponsiveSidebarNavigation";
import AppContentErrorBoundary from "src/Modules/Shared/AppContentErrorBoundary";
import styled from "styled-components";

type Props = {
  navigation: ReactNode;
  content: ReactNode;
};

const DevBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100vw;
  height: 12px;
  background: blue;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 2px;
`;

const AppLayout: React.FC<Props> = (props) => {
  let isDev = import.meta.env.MODE == "development";

  return (
    <div className={appLayoutStyle}>
      <AppResponsiveSidebarNavigation content={props.navigation} />
      <div className={appContentStyle}>
        {/*
        {isDev && <DevBar>Development</DevBar>}
        */}
        {props.content}
      </div>
      {/*


        <div className={contentContainerStyle}>
          <AppContentErrorBoundary>{props.content}</AppContentErrorBoundary>
        </div>
      </div>
      */}
    </div>
  );
};

export default AppLayout;
