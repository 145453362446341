import React from "react";
import styled from "styled-components";
import { FieldRenderProps } from "react-final-form";
import Select from "react-select";
import skillOptions from "src/Modules/Shared/Components/Temp/skillOptions";

const stylesOption = {
  container: (provided: any, state: any) => ({
    ...provided,
    width: "100%",
    height: 48,
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    color: "red",
    display: "none",
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    display: state.isMulti ? "none" : "default",
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    margin: 0,
    top: 60,
    border: "1px solid #eceff6",
    borderTop: "none",
    zIndex: 1000,
    maxHeight: 100,

    background: "#FFFFFF",
    boxShadow: "0px 2px 16px rgba(28, 39, 74, 0.06)",
    borderRadius: 6,
  }),
  menuPortal: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    margin: 0,
    top: 48,
    zIndex: 1,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 48,
    fontFamily: "Inter",
    paddingRight: 12,
    margin: 0,
    color: "#1C274A",
    border: state.isFocused ? "#105cf7 1px solid" : "1px solid #EDEDF0",
    "&:hover": {
      borderColor: "#7aa0ff 1px solid",
    },
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: 14,
    color: state.isDisabled ? "#999" : "#1C274A",
    letterSpacing: "0",
    fontStyle: "normal",
    fontFamily: "Inter",
    fontWeight: 400,
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    borderRadius: 6,
    padding: "2px 8px",
    backgroundColor: "#F4FBFD",
    height: 24,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  }),
  multiValueRemove: (provided: any, state: any) => ({
    ...provided,
    color: "#1D7C93",
    backgroundColor: "none",
    width: 18,
    height: 18,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: "pink",
      cursor: "pointer",
    },
  }),
  multiValueLabel: (provided: any, state: any) => ({
    ...provided,
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Inter",
    color: "#1D7C93",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: 20,
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: 14,
    height: 20,
    fontFamily: "Inter",
    fontWeight: 400,
    marginLeft: 0,
    color: "#858B9D",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: 12,
    fontWeight: 500,
    display: "flex",
    alignItems: "center",

    height: 48,
    backgroundColor: state.isSelected
      ? "#F8FAFF"
      : state.isFocused
      ? "#F8FAFF"
      : "white",
    color: state.isSelected
      ? "#1C274A"
      : state.isFocused
      ? "#1C274A"
      : "#1C274A",
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
  }),
};

const CustomDropdownIndicator = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 12.5001L6.46417 8.96422L7.64334 7.78589L10 10.1434L12.3567 7.78589L13.5358 8.96422L10 12.5001Z"
        fill="#9FA4B3"
      />
    </svg>
  );
};

const Container = styled.div`
  width: 100%;
  height: 48px;
`;

type Props = FieldRenderProps<string, any> & { toRemove: [] };

function getOptionByValue(options: any, value: any) {
  if (value === "") {
    return null;
  }

  if (Array.isArray(value)) {
    let selectedOptions: any[] = [];
    for (let item of value) {
      var found = options.find((o: any) => {
        return o.value == item;
      });
      selectedOptions.push(found);
    }
    return selectedOptions;
  } else {
    return options.find((o: any) => {
      return o.value == value;
    });
  }

  return null;
}

const CommonMultipleSkillsSelect: React.FC = (props) => {
  let rev = props.toRemove ?? [];

  let finalOptions = skillOptions.filter((i: any) => {
    if (
      rev.find((j) => {
        return j == i.value;
      })
    ) {
      return false;
    } else {
      return true;
    }
  });

  return (
    <Container>
      <Select
        components={{ DropdownIndicator: CustomDropdownIndicator }}
        styles={stylesOption}
        options={finalOptions}
        placeholder={props.placeholder}
        isDisabled={props.disabled}
        isMulti={true}
        onChange={(e) => {
          if (Array.isArray(e)) {
            let values: any[] = [];
            for (let item of e) {
              let c: any = item;
              values.push(c.value);
              props.input.onChange(values);
            }
          } else {
            const newInput = e.value;
            props.input.onChange(newInput);
          }
        }}
        value={getOptionByValue(finalOptions, props.input.value)}
        {...props}
      />
    </Container>
  );
};

export default CommonMultipleSkillsSelect;
