import React, { useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { FieldRenderProps } from "react-final-form";
import { DefaultSelectStyle } from "src/Modules/Core/Components/Form/Select/DefaultSelectStyle";
import {
  DropdownIndicator,
  Option,
  ValueContainer,
} from "src/Modules/Core/Components/Form/Select/SelectComponents";
import JsonDump from "src/Modules/Shared/Utils/JsonDump";
import { useMediaQuery } from "usehooks-ts";

const Container = styled.div`
  width: 100%;
  min-height: 48px;
  height: fit-content;
  .react-select__control {
    min-height: 48px;
    height: fit-content;
  }

  .react-select__value-container {
    flex-wrap: wrap;
    height: fit-content;
    display: flex;
  }

  div[class$="container"] {
    height: fit-content;
  }

  .react-select__container {
    height: fit-content;
    margin: 20px;
  }
`;

const ContainerStatic = styled.div`
  min-height: 48px;
  height: 48px;

  .react-select__control {
    min-height: 48px;
    height: fit-content;
    overflow: scroll;
  }

  .react-select__container {
    height: fit-content;
    margin: 20px;
  }

  .react-select__value-container {
    width: 30px;
  }
`;

type SelectFieldProps = FieldRenderProps<[], any>;

const DefaultSelectField: React.FC<SelectFieldProps> = (props) => {
  let selectedOptions = null;
  let isMobile = useMediaQuery("(max-width: 600px)");

  if (props.isMulti) {
    if (Array.isArray(props.input.value)) {
      selectedOptions = props.options.filter((option: any) => {
        let isSelected = props.input.value.find((i) => {
          return i == option.value;
        });

        return isSelected != undefined;
      });
    }
  } else {
    selectedOptions = props.options.filter((option: any) => {
      return option.value === props.input.value;
    });
  }

  let RenderContainer = Container;

  if (props.static == "MobileOnly") {
    if (isMobile) {
      RenderContainer = Container;
    } else {
      RenderContainer = ContainerStatic;
    }
  }

  let SelectComponent = Select;

  if (props.creatable) {
    SelectComponent = CreatableSelect;
  }

  return (
    <RenderContainer>
      <SelectComponent
        closeMenuOnSelect={!props.isMulti}
        isMulti={props.isMulti}
        hideSelectedOptions={false}
        classNamePrefix="react-select"
        components={{
          DropdownIndicator: DropdownIndicator,
          Option: Option,
          ValueContainer: ValueContainer,
        }}
        styles={DefaultSelectStyle}
        options={props.options}
        placeholder={props.placeholder}
        isDisabled={props.disabled}
        value={selectedOptions}
        onChange={(newValue: any, actionMeta: any) => {
          if (props.isMulti) {
            props.input.onChange(newValue.map((i: any) => i.value));
          } else {
            props.input.onChange(newValue.value);
          }
        }}
        {...props}
      />
    </RenderContainer>
  );
};

export default DefaultSelectField;
