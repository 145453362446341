import React from "react";
import styled from "styled-components";

const Container = styled.div``;

const mapToLabel = (value, options) => {
  let f = options.find((i) => {
    return i.value == value;
  });

  return f?.label ?? value;
};

type Props = {
  value: string;
  options: [];
};

const OptionLabelByValue: React.FC<Props> = (props) => {
  let label = mapToLabel(props.value, props.options);

  return <span>{label}</span>;
};

export default OptionLabelByValue;
